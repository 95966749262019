// HEADER
//////////////////////////////
.contactBar {
  ul {
    display: none;

    @include breakpoint(large) {
      display: flex;
      margin-left: rem(20px);
    }

    @include breakpoint(giant) {
      margin-left: 0;
      justify-content: space-around;
    }
  }

  .link-holder {
    float: left;
    position: relative;

    @include breakpoint(large) {
      margin-left: 1rem;
    }

    &.book {
      a {
        span {
          transition: 0.5s ease-in-out;
        }

        &:before {
          transition: 0.5s ease-in-out;
        }
      }
    }

    a {
      span {
        &:hover {
          background: $light;

          &:before {
            color: $dark;
          }
        }
      }
    }

    .icon {
      display: inline-block;
      padding: 0.9rem;
      background: $red;
      border-radius: 1.7rem;
      transition: 0.5s ease-in-out;
    }

    &:hover {
      & > div {
        visibility: visible;
        opacity: 1;
      }
    }

    .icon {
      &:before {
        color: $light;
        transition: 0.5s ease-in-out;
      }
    }

    div {
      background: $dark;
      position: absolute;
      color: $light;
      padding: 1rem;
      text-align: center;
      top: 0;
      right: 0;
      margin-top: 3.6rem;
      visibility: hidden;
      opacity: 0;
      min-width: 12rem;
      font-size: rem(14px);
      font-weight: 200;
      transition: 0.5s ease-in-out;

      @include breakpoint(huge) {
        right: auto;
        left: 50%;
        transform: translatex(-50%);
      }

      a {
        color: $light;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.topBar {
  .row {
    align-items: center;
  }
}

#header {
  position: relative;
  padding: 7rem 0 5rem;
  width: 100%;
  margin-bottom: 3rem;
  text-align: center;
  color: $light;
  background-size: cover;
  background-position: top;

  body:not(.index) & {
    padding: 10rem 0 4rem;

    @include breakpoint(huge) {
      margin-bottom: 6rem;
    }

    .claim {
      display: none;
    }

    .iconbar {
      top: 91%;
    }
  }

  @include responsiveImage("layout/bgHeader.jpg", (tiny, medium, large, huge), false);

  &.lazyLoaded {
    background-size: cover;
  }

  @include breakpoint(medium) {
    padding: 8rem 0 8rem;
    margin-bottom: 6rem;
    font-size: rem(25px);
    line-height: rem(34px);
  }

  @include breakpoint(huge) {
    padding: 11rem 0;
    margin-bottom: 8rem;
    background-attachment: fixed;
  }

  .claim {
    p {
      margin: 1.5rem 0;
      font-weight: 300;

      &.small {
        margin: 1rem 0 0 0;
        font-size: rem(16px);
      }

      @include breakpoint(small) {
        margin: 2.5rem 0;
      }

      @include breakpoint(medium) {
        margin: 4.5rem 0;
      }
    }
  }

  .branding {
    display: inline-block;
    text-align: center;

    img {
      width: auto;
    }
  }

  .iconbar {
    position: absolute;
    top: 95%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    text-transform: uppercase;

    // @include breakpoint (small) {
    //   top: 89%;
    // }
    @include breakpoint(small) {
      top: 94%;
    }

    .bold {
      font-weight: bold;
      margin-left: 10px;
      font-size: 20px;
      display: none;

      // @include breakpoint (small) {
      //   display: inline-block;
      //   font-size: 13px;
      // }
      @include breakpoint(large) {
        display: inline-block;
        font-size: 14px;
      }

      @include breakpoint(giant) {
        display: inline-block;
        font-size: 18px;
      }

      @include breakpoint(full) {
        font-size: 24px;
      }
    }
  }
}

.signUp {
  position: relative;
  padding-top: rem(164px);
  text-align: center;

  @include breakpoint(giant) {
    padding-top: 0;
    text-align: left;
    padding-left: rem(185px);
  }

  .h1 {
    font-size: 2.3rem;

    @include breakpoint(medium) {
      margin-bottom: 1rem !important;
    }

    @include breakpoint(giant) {
      text-align: left;
      margin-left: 1rem;
    }
  }

  &:before {
    position: absolute;
    top: 0;
    content: "";
    background: url(../images/index/trainer.jpg);
    padding: rem(80px);
    left: 50%;
    transform: translatex(-50%);

    @include breakpoint(giant) {
      left: 0;
      transform: translatey(-50%);
      top: 50%;
    }
  }
}

.teaserContainer {
  display: flex;
  align-items: center;
  margin: 5rem 0 7rem;
  transition: 0.5s ease-in-out;

  @include breakpoint(medium) {
    margin: 6rem 0 8rem;
  }

  @include breakpoint(large) {
    margin: 8rem 0 8rem;
  }

  &.second {
    margin: 3rem 0 3rem;

    @include breakpoint(medium) {
      margin: 6rem 0 8rem;
    }

    @include breakpoint(large) {
      margin: 8rem 0 8rem;
    }
  }

  .teaserBox {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-bottom: 2.5rem;

    p {
      flex-basis: 100%;
    }

    .h1 {
      flex-basis: 100%;
      margin-bottom: 1rem !important;
    }
  }

  .tns-nav {
    display: none;
  }

  .tns-controls {
    display: block;
    position: absolute;
    bottom: 0;
    margin-bottom: -4.3rem;

    button {
      background: $red;
      border: none;
      padding: 0rem;
      color: $light;
      margin-right: 0.3rem;
      font-family: $displayFont;
      font-size: 2.22222rem;
      height: 4rem;
      width: 4rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      transition: 0.5s linear all;

      &:hover {
        background: $dark;
      }
    }
  }

  .ts-01 {
    // background: url(../images/teaser/personal-training.jpg);
    @include responsiveImage("teaser/personal-training.jpg", (tiny, small, medium, large, huge), false);
    background-size: cover;
  }

  .ts-02 {
    // background: url(../images/teaser/semi-private-training.jpg);
    @include responsiveImage("teaser/semi-private-training.jpg", (tiny, small, medium, large, huge), false);
    background-size: cover;

    @include breakpoint(small, max) {
      background-position: center;
    }
  }

  .ts-03 {
    // background: url(../images/teaser/nutrition-plans.jpg);
    @include responsiveImage("teaser/nutrition-plans.jpg", (tiny, small, medium, large, huge), false);

    background-size: cover;

    @include breakpoint(small, max) {
      background-position: center;
    }
  }

  .ts-04 {
    // background: url(../images/teaser/kids-fit-clinic.jpg);
    @include responsiveImage("teaser/kids-fit-clinic.jpg", (tiny, small, medium, large, huge), false);
    background-size: cover;
  }

  .ts-05 {
    // background: url(../images/teaser/bootcamp.jpg);
    @include responsiveImage("teaser/bootcamp.jpg", (tiny, small, medium, large, huge), false);
    background-size: cover;

    @include breakpoint(small, max) {
      background-position: center;
    }
  }

  .ts-01,
  .ts-02,
  .ts-03,
  .ts-04,
  .ts-05 {
    padding: 15rem 2rem 2rem;
    display: block;
    text-decoration: none;
    color: $light;
    background-size: cover;
    overflow: hidden;
    min-height: rem(350px);
    max-height: rem(350px);
    font-size: rem(24px);
    font-weight: 300;
    letter-spacing: rem(0.5px);
    position: relative;
    transition: 0.3s ease-in-out;

    @include breakpoint(medium) {
      min-height: rem(650px);
      padding: 32.5rem 2rem 2rem;
      max-height: rem(650px);
    }

    &:after {
      background: linear-gradient(to top, black 10%, transparent 50%);
      content: "";
      position: absolute;
      width: 100%;
      height: 50%;
      left: 0;
      transition: 0.3s ease-in-out;
      bottom: 0;
      opacity: 0.8;
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 0%;
      left: 0;
      transition: 0.3s ease-in-out;
      bottom: 0;
      opacity: 0.7;
      background: linear-gradient(to top, black 100%, transparent 100%);
      visibility: hidden;
      &:hover,
      &:active {
      }
    }

    span {
      position: relative;
      display: block;
      margin-top: 0;
      z-index: 99;
      transition: 0.3s ease-in-out;
    }

    .small {
      opacity: 0;
      font-weight: bold;
      visibility: hidden;
      font-size: rem(20px);
      z-index: 999;
      text-transform: uppercase;
      transition: 0.3s ease-in-out;

      &:after {
        content: "+";
        font-family: $displayFont;
        font-size: rem(45px);
        position: absolute;
        margin-left: 0.8rem;
        top: 0.3rem;
        transition: 0.3s ease-in-out;
      }
    }
    &:hover,
    &:focus {
      &:before {
        height: 100%;
        visibility: visible;
      }

      span {
        margin-top: -33px;
      }

      .small {
        opacity: 1;
        visibility: visible;
        display: block;
        margin-top: 0.4rem;
      }
    }
  }
}

.uspContainer {
  @include responsiveImage("layout/bgUsp.jpg", (tiny, medium, large, huge), true);
  padding: 3rem 0;
  color: $light;
  background-size: cover;

  @include breakpoint(medium) {
    padding: 6rem 0;
  }

  @include breakpoint(large) {
    padding: 8rem 0;
  }

  @include breakpoint(huge) {
    background-attachment: fixed;
  }

  .h1 {
    span {
      color: $light;

      @include breakpoint(large) {
        margin-left: 20rem;
      }
    }
  }

  ul {
    &.noMargin {
      margin-bottom: 0;
    }

    li {
      padding-top: rem(30px) !important;
      padding-bottom: rem(30px) !important;
      border-bottom: 1px solid rgba($light, 0.5) !important;
      position: relative;
      padding-left: 3rem !important;

      &:last-child {
        @include breakpoint(large) {
          border: none !important;
        }
      }

      @include breakpoint(giant) {
        font-size: rem(22px);
      }

      &:before {
        position: absolute;
        padding: 1.1rem;
        content: "";
        background: url(../images/layout/usp.png);
        left: 0;
        top: rem(23px);
      }

      &.usp-01 {
        &:before {
          background-position: center -0.6rem;
        }
      }

      &.usp-02 {
        &:before {
          background-position: center -2.9rem;
        }
      }

      &.usp-03 {
        &:before {
          background-position: center -5.6rem;
        }
      }

      &.usp-04 {
        &:before {
          background-position: center -8.3rem;
        }
      }

      &.usp-05 {
        &:before {
          background-position: center -10.9rem;
        }
      }

      &.usp-06 {
        &:before {
          background-position: center -13.6rem;
        }
      }

      &.usp-07 {
        &:before {
          background-position: center -16.9rem;
        }
      }

      &.usp-08 {
        &:before {
          background-position: center -19.9rem;
        }
      }

      &.usp-09 {
        &:before {
          background-position: center -23.1rem;
        }
      }

      &:last-child {
        @include breakpoint(medium) {
          border: none;
        }
      }
    }
  }

  .center {
    text-align: center;
  }
}

.testimonialContainer {
  margin: 3rem 0 3rem;

  @include breakpoint(medium) {
    margin: 6rem 0 6rem;
  }

  @include breakpoint(large) {
    margin: 8rem 0 11rem;
  }

  .tns-outer {
    position: relative;
  }

  .tns-nav {
    display: none;
  }

  .tns-controls {
    display: block;
    position: absolute;
    bottom: 0;
    margin-bottom: -4.3rem;
    right: 0;

    button {
      background: $red;
      border: none;
      padding: 0rem;
      color: $light;
      margin-right: 0.3rem;
      font-family: $displayFont;
      font-size: 2.22222rem;
      height: 4rem;
      width: 4rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      transition: 300ms linear all;

      &:hover {
        background: $dark;
      }
    }
  }

  .teaserBox {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 7.5rem;

    @include breakpoint(large) {
      margin-top: 0;
    }

    p {
      flex-basis: 100%;
    }

    .h1 {
      flex-basis: 100%;
      margin-bottom: 1rem !important;

      @include breakpoint(medium) {
        font-size: 2.5rem;
      }
    }
  }

  .inner {
    background: $dark;
    color: $light;
    text-align: center;
    padding: 3rem;
    min-height: 25rem;

    @include breakpoint(tiny) {
      min-height: 24rem;
    }

    @include breakpoint(small) {
      min-height: 33.3rem;
    }

    @include breakpoint(medium) {
      min-height: 25.3rem;
    }

    @include breakpoint(large) {
      min-height: 22.3rem;
    }

    @include breakpoint(giant) {
      min-height: 27.5rem;
    }

    @include breakpoint(full) {
      min-height: 24.6rem;
    }

    span {
      font-size: rem(24px);
    }

    .stars {
      background: url(../images/layout/stars.png) no-repeat;
      width: rem(146px);
      height: rem(22px);
      background-size: cover;
      left: 50%;
      transform: translatex(-50%);
      position: relative;
    }
  }
}

.aboutContainer {
  @include responsiveImage("layout/bgAbout.jpg", (tiny, medium, large, huge), true);
  padding: 3rem 0;
  color: $light;
  background-size: cover;
  text-align: center;

  @include breakpoint(medium) {
    padding: 6rem 0;
  }

  @include breakpoint(large) {
    padding: 8rem 0;
  }

  @include breakpoint(huge) {
    background-attachment: fixed;
  }

  .h1 {
    @include breakpoint(medium) {
      margin-bottom: 2rem !important;
    }

    span {
      color: $light;

      @include breakpoint(large) {
        margin-left: 18rem;
      }
    }
  }
}

.footerContainer {
  text-align: center;
  position: relative;

  @include breakpoint(medium) {
    margin: 6rem 0;
  }

  @include breakpoint(giant) {
    margin: 8rem 0;
  }

  .h1 {
    margin-bottom: 1rem !important;

    &.small {
      margin-bottom: 2rem !important;

      @include breakpoint(medium) {
        font-size: rem(29px);
      }
    }
  }

  .map {
    display: block;
  }

  .contactBox {
    margin: 3rem 0;
  }

  .socials {
    left: 50%;
    position: relative;
    transform: translatex(-50%);

    li {
      display: inline-block;
      border: none;
      margin: 0 0.5rem;
    }

    a {
      position: relative;
      padding: 1.1rem 1.7rem;
      border-radius: 2rem;
      background: $red url(../images/layout/socials.png);

      &:hover {
        background: $dark url(../images/layout/socials.png);
      }

      &.fb {
        background-position: center 0.3rem;
      }

      &.insta {
        background-position: center -2.4rem;
      }

      &.yelp {
        background-position: center -5.1rem;
      }
    }
  }

  iframe {
    width: 100%;
    height: rem(300px);

    @include breakpoint(medium) {
      position: absolute;
      width: 46%;
      top: 0;
      height: 100%;
      right: 0;
    }

    @include breakpoint(huge) {
      width: 59%;
    }
  }
}

// MAIN CONTENT
//////////////////////////////

main {
  display: block;

  p,
  ol,
  ul,
  dl,
  table {
    @extend .marginBottom;
    font-weight: 300;
  }

  ul:not(.unstyledList),
  dl:not(.unstyledList) {
    @extend .styledList;
  }

  .googleMaps {
    @extend .videoContainer;

    @include breakpoint(medium) {
      height: rem(400px);
    }
  }
}

#privacyPolicy {
  a {
    display: inline-block;
    word-break: break-all;
  }
}

// FOOTER
//////////////////////////////

#footer {
  .col {
    padding: 0;

    @include breakpoint(medium) {
      padding: 0 $baseGap * 2;
    }
  }

  background: $dark;

  .navi {
    text-align: center;
    padding-bottom: 4rem;
    // @include breakpoint(small) {
    //   padding-bottom: 0;
    // }

    @include breakpoint(small) {
      text-align: left;
      padding-bottom: 0;
    }

    li {
      border-bottom: 1px solid rgba($light, 0.3);

      @include breakpoint(small) {
        display: inline-block;
        border: none;
      }

      a {
        display: block;
        font-weight: 700;
        color: $light;
        text-transform: uppercase;
        text-decoration: none;
        padding: 1rem;

        &:hover,
        &.active {
          background: $red;
        }
      }
    }
  }
}

.fadeLeft,
.fadeRight,
.fadeTop,
.fadeBottom {
  @include breakpoint(large) {
    opacity: 0;
    visibility: hidden;
  }

  @for $i from 1 through 4 {
    &:nth-of-type(4n + #{$i}) {
      transition:
        all 0.5s (0.15s * $i) ease-in-out,
        opacity 0.5s (0.15s * $i) ease-in-out;
    }
  }

  &.show {
    @include breakpoint(large) {
      opacity: 1;
      visibility: visible;
      transform: none;
    }
  }
}

.fadeLeft {
  @include breakpoint(large) {
    transform: translate(2rem, 0);
  }
}

.fadeRight {
  @include breakpoint(large) {
    transform: translate(-2rem, 0);
  }
}

.fadeTop {
  @include breakpoint(large) {
    transform: translate(0, 2rem);
  }
}

.fadeBottom {
  @include breakpoint(large) {
    transform: translate(0, -2rem);
  }
}

body.internetExplorer {
  .uspContainer {
    background-attachment: scroll !important;
  }

  .AboutContainer {
    background-attachment: scroll !important;
  }

  #header {
    background-attachment: scroll !important;
  }
}

body.cmsBackend {
  #header {
    background: $dark;
  }

  #navigation {
    position: relative;
    background: $dark;
  }
}

.center {
  text-align: center;
}

.container {
  margin: 1rem auto;
}

body.shopWhyShop {
  .loop-row.row {
    margin-bottom: 2rem;

    &:nth-of-type(2) {
      .col.medium-6:nth-of-type(1) {
        order: 2;

        @include breakpoint(medium) {
          order: 0;
        }
      }
    }
  }
}

.hp_bannerFlexbox {
  .flexboxContent {
    display: flex;
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
    padding: rem(40px);
    color: white;

    .white {
      text-shadow: $dark 1px 0 10px;
      color: white;

      @include breakpoint(large) {
        text-shadow: unset;
      }
    }

    h2,
    h3 {
      color: white;
    }

    @include breakpoint(medium) {
      .container {
        width: 750px;
      }
    }

    .flexItem.flex-c-r {
      align-self: flex-end;
      margin-left: auto;
    }

    .hp_bannerFlexbox .flexboxContent .flexItem {
      display: inline-block;
      text-shadow: rgba(0, 0, 0, 0.2) 0px 0px 3px;
    }

    .text-left {
      text-align: left;
    }
  }

  .hp_showSmall {
    display: block;
    margin: 1rem;

    @include breakpoint(large) {
      display: none;
    }
  }

  .hp_hideSmall {
    display: none;

    @include breakpoint(large) {
      display: block;
    }
  }

  .hp_button.white {
    border: 2px solid #fff;
    border-radius: 2px;
    background-color: transparent;
    color: #fff;
    padding: 15px 49px;
    font-family: "Bebas Neue W05 Regular";
    font-weight: 400;
    transition:
      color 0.3s,
      background-color 0.3s;
    text-decoration: none;
    line-height: 1em;
    text-shadow: rgba(0, 0, 0, 0.2) 0px 0px 3px;

    &:hover,
    &:focus {
      background: $red;
    }
  }

  .hp_bebas {
    font-family: "Bebas Neue W05 Regular";
    font-weight: 400;
    text-shadow: rgba(0, 0, 0, 0.2) 0px 0px 3px;
  }

  .hp_athelas {
    font-family: "Athelas W05 Italic";
  }
}

.covid-message {
  margin: 3rem 0 0;
  background-color: $dark;

  @include breakpoint(large) {
    margin-top: rem(90px);
  }

  @include breakpoint(giant) {
    margin-top: rem(97px);
  }

  .covid-p {
    background: $red;
    width: 100%;
    color: $light;
    padding: 1rem;
    text-align: center;
  }

  .white-href {
    color: $light;
    text-decoration: underline;

    &:hover {
      color: $dark;
    }
  }
}

.logoes {
  margin-bottom: 3rem;

  @include breakpoint(medium) {
    margin-bottom: 5rem;
  }

  @include breakpoint(large) {
    margin-bottom: 8rem;
  }

  .col {
    text-align: center;

    img {
      max-width: rem(300px);
    }
  }
}

.WidgetBannerContainer {
  z-index: 999 !important;
}

body.contactFormConfirmation h1,
body.contactFormError h1 {
  text-align: left !important;
}

.why-shop {
  a {
    .why-shop-img {
      @extend %animatedTransform;

      @include breakpoint(medium, max) {
        margin-bottom: rem($baseLineHeight);
        width: auto;
      }

      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }
  }
}

.evercaptcha {
  box-shadow:
    rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.WidgetPopup {
  @include breakpoint(large, max) {
    margin-top: rem(55px) !important;
  }
}
.popup{
  .popUpContainer {
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
    transition: opacity 0.3s, visibility 0.3s;
    position: fixed;
    visibility: visible;
    opacity: 1;
    z-index: 100000;
  
    /* Popup Content */
    .popUpContent {
        width: 100%;
        background: #f9f9f9; /* Light background color */
        max-height: 90vh;
        max-width: 100%; /* To account for the close button */
        overflow-y: auto;
        padding: rem(50px) rem(20px);
        p{
          margin-bottom: 0;
          text-align: center;
          font-size: rem(22px);
          .highlight{
            font-weight: bold;
            color: $red;
          }
        }
    }
  }
  
  label {
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    height: 100%;
    justify-content: flex-end;
    padding: 20px;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: -1;
  
    i {
        cursor: pointer;
        display: block;
        height: 35px;
        width: 35px;
        position: relative;
        transition: background 0.3s;
  
        &:hover {
            background: rgba(0, 0, 0, 0.8);
  
            &:before,
            &:after {
                background: white;
            }
        }
  
        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 30px;
            height: 2px;
            background: #ddd;
        }
  
        &:before {
            transform: translate(-50%, -50%) rotate(45deg);
        }
  
        &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }
  }
  
  input[id^="popUpToggled"] {
    display: none;
  
    /* If checkbox is not checked, hide the popup */
    &:not(:checked) + .popUpContainer {
        position: relative;
        opacity: 0;
        visibility: hidden;
        z-index: -1000000;
        transform: scale(0);
        pointer-events: none;
        width: 0;
        height: 0;
    }
  }
}
