// Relativ path to images folder (used in the image-url() function)
$imagePath: "../images/" !default;

// +++++ Font settings
// Font size of main content (in px)
$baseFontSize: 18px;

// Line height of main content (in px)
$baseLineHeight: 26px;

// Headline font sizes (in px)
$h1Size: 57px;
$h2Size: 24px;
$h3Size: 24px;
$h4Size: 24px;
$h5Size: 24px;
$h6Size: 24px;

// Font stacks: $mainFont for simple content, $displayFont for headlines etc., $iconFont for icons
$mainFont:    "Roboto", sans-serif;
$displayFont: "Homemade Apple", cursive;
$iconFont:   sans-serif;
$iconFont:   "icomoon";

// +++++ Design & Layout
// Colours for your design.
$primary:   #726A5B;
$secondary: #EDE8DD;
$light:     #fff;
$medium:    #F2F2F0;
$dark:      #141414;
$border:    #bbb;
$alert:     #D85D00;
$red:       #bd0001;

// Horizontal padding left and right of grid columns
$baseGap: 18px;

// Grid row max-width
$rowMaxWidth: 1400px;

// Grid columns
$columnCount: 12;

// Standard border for your design
$baseBorder: 1px solid $border;

// Standart outline styles for focus states
$outline: 1px dotted $alert;

// Assume a different base font size for breakpoint em calculation (default: 16px)
$bpContext: 16px;

// +++++ Miscellaneous
// text-indent value when using hideText(): "right" = positive value, "left" = negative value
$hideTextDirection: "right";

// $iconMap: (
//     times: "×",
//     minus: "-",
//     angle-right: "❯",
//     plus: "+",
//     angle-up: "↑",
//     exclamation: "!"
// );

// EXAMPLE FOR USE WITH ICONFONT
$iconMap: (
    angle-right: "\e902",
    angle-up: "\e903",
    exclamation: "\e90d",
    minus: "\e90c",
    plus: "\e90a",
    times: "\e909",
    handle: "\e9fa",
    heart: "\ea00",
    heartbeat: "\ea01",
    line-person: "\ea26",
    line-person-two: "\ea28",
    spa-lotus: "\eae5",
    location: "\e947",
    calendar: "\e953",
    star: "\e9d9",
    phone: "\f095",
    chevron-right: "\f054",
    chevron-left: "\e900"
);


/*  Grid Map 
    Define your breakpoints and grid classes below.
    Only generate the gridclasses you really need to reduce filesize.
    example:
    tiny: ( <-- "breakpoint name"
        width:  em(480px, $bpContext), <-- "breakpoint width"
        cols: (5,6,12), <-- "columns" result generates .tiny-5, .tiny-6 and .tiny-12
        prefix: (2,4), <-- "prefix" result generates .tiny-prefix-2 and .tiny-prefix-4
        suffix: (6,8), <-- "suffix" result generates .tiny-suffix-6 and .tiny-suffix-8
        center: (align-item: center), <-- resutl generates .tiny-center {align-item: center} for breakpoint tiny
        flex: (display: flex) <-- resutl generates .tiny-flex {display: flex} for breakpoint tiny
    )
*/
$gridMap: (
    tiny: (
        width:  em(480px, $bpContext),
        cols: (6)
    ),
    small: (
        width:  em(640px, $bpContext)
    ),
    medium: (
        width:  em(760px, $bpContext),
        cols: (4,6,8,10)
    ),
    large: (
        width:  em(992px, $bpContext),
        cols: (2,3,4,6,8,9,10)
    ),
    giant: (
        width:  em(1200px, $bpContext),
        cols: (4,7,8,12)
    ),
    huge: (
        width:  em(1364px, $bpContext),
        cols: (4,6)
    ),
    full: (
        width:  em(1520px, $bpContext)
    )
);
