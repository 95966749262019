// Use this for default Icomoon

@include fontFaceV2($fontName: "icomoon",
    $fileName: "icomoon",
    $types: (woff, ttf, svg, eot),
    $style: normal,
);

// Example font-face for Roboto
// @include fontFaceV2(
//     $fontName: "Roboto", 
//     $fileName: "roboto-v19-latin", 
//     $weights: ("400", "700", "900", "900|italic"), 
//     $types: all
// );

@include fontFaceV2($fontName: "Homemade Apple",
    $fileName: "homemade-apple-v9-latin",
    $weights: ("400"),
    $types: all);
@include fontFaceV2($fontName: "Roboto",
    $fileName: "roboto-v19-latin",
    $weights: ("300", "400", "500", "700"),
    $types: all);
@include fontFaceV2($fontName: "Bebas Neue W05 Regular",
    $fileName: "BebasNeue",
    $weights: ("400"),
    $types: (ttf));