// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

$desktopHover: false; // Set Hover to reach Submenus for Desktop (true, false)

// NAVIGATION
//////////////////////////////////////////////

$navBg: $dark; // Navigation Background
$navColor: $light; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none;
	color: $navColor;
}
%buttonHover {
	// Link Hover
	color: $light;
	background-color: $red;
}
%buttonActive {
	// Link Active
	color: $light;
	background-color: $red;
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotateY(180deg);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $red; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;

	.col {
		padding: 0.2px;
	}

	nav {
		@include ul {
			&.navi {
				@include breakpoint(large,max){
					padding-bottom: rem(100px);
				  }
				li {
					border-bottom: 1px solid rgba($light,0.3);
					@include navButtons {
						@include navButton;
					}
					a, span {
						font-weight: 700;
						text-transform: uppercase;
						font-size: rem(20px);
					}
					@include hasSub {
						@include sub {
							background: $navBg;

							li {
								a {
									text-transform: none;
									font-size: rem(18px);
								}
							}

							&:before {
								padding: 1em rem($baseGap);
								background-color: rgba($light,0.2);
								color:$light;
								font-weight: 700;
							}
						}
					}
					@include navBack {
					}
				}
			}
		}
	}
}

// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;


	

	#navigation {
		z-index: 99;
		@include breakpoint(giant){
			.col {
				padding: 0 $baseGap*2;
			}
		}

		background: $dark;
		padding: 1rem 0;

		&.scroll {
			background: $dark;
		}
		nav {
			ul {
				&.navi {
					li {
						border:none;
						a {
							font-size: rem(16px);
							padding: 0.8rem;
							@include breakpoint(giant) {
								font-size: rem(18px);
								padding: 1rem;
							 }
							@include breakpoint(huge) {
								font-size: rem(20px);
							 }
						}
						&.hasSub {
							span {
								font-size: rem(16px);
								padding: 0.8rem;
								@include breakpoint(giant) {
								font-size: rem(18px);
								padding: 1rem;
							 }
							@include breakpoint(huge) {
								font-size: rem(20px);
							 }
							}
							.sub {
								min-width: 100%;
								text-align: center;
								li {
									border-bottom: 1px solid rgba($light,0.3);
										a {
											text-transform: none;
											font-size: rem(18px);
										}
								}
							}
						}
						&.nav-back {
							&:before {
								transform: none;
							}
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	@extend %animatedTransform;
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;

	@include breakpoint($break) {
		display: none;
	}
}
